import { Global } from "@emotion/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import tw, { GlobalStyles } from "twin.macro";
import { AuthProvider } from "./login/AuthContext";
import { Routes } from "./Routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const App = () => {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s - Support BECOMS" />
      <GlobalStyles />
      <Global styles={{ body: tw`antialiased font-sans` }} />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
