import { useMutation, useQuery } from "react-query";
import { useAPI } from "../shared/useAPI";
import { useAuth } from "./AuthContext";

/** @typedef {{ username: string, password: string }} UseLoginMutationVariables */
/** @typedef {{ session_token: string }} UseLoginMutationData */
/** @type {() => import("react-query").UseMutationResult<UseLoginMutationData, unknown, UseLoginMutationVariables>} */
export const useLoginMutation = () => {
  const api = useAPI();
  const { setSessionToken } = useAuth();
  return useMutation(async ({ username, password }) => {
    const response = await api
      .get("initSession", {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      })
      .json();
    setSessionToken(response.session_token);
    return response;
  });
};

/** @typedef {{ email: string }} ForgotPasswordPayload */
/** @type {() => import("react-query").UseMutationResult<ForgotPasswordPayload>} */
export const useForgotPasswordMutation = () => {
  const api = useAPI();
  return useMutation(async (json) => {
    return api.put("lostPassword", { json }).json();
  });
};

/** @type {({ sessionToken: string }) => import("react-query").UseQueryResult<object>} */
export const useFullSessionQuery = ({ sessionToken }) => {
  const api = useAPI();
  return useQuery(
    "getFullSession",
    async () =>
      api
        .get("getFullSession", {
          headers: {
            "Session-Token": sessionToken,
          },
        })
        .json(),
    {
      enabled: !!sessionToken,
    }
  );
};
