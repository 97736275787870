import { createContext, useContext } from "react";
import { useQueryClient } from "react-query";
import { useLocalStorage } from "../shared/useLocalStorage";
import { useFullSessionQuery } from "./LoginQueries";

const AuthContext = createContext();

/**
 * @typedef {{
 * userId: number,
 * isAuthenticated: boolean,
 * logout: () => void,
 * sessionToken: string,
 * setSessionToken: React.Dispatch<React.SetStateAction<string>
 * }}
 * AuthContextValue */

/** @type {React.FC<{}>} */
export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [sessionToken, setSessionToken] = useLocalStorage("sessionToken", null);
  const { data: fullSession } = useFullSessionQuery({ sessionToken });
  return (
    <AuthContext.Provider
      value={{
        userId: fullSession?.session?.glpiID,
        isAuthenticated: sessionToken !== null,
        logout: () => {
          queryClient.clear();
          setSessionToken(null);
        },
        sessionToken,
        setSessionToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/** @type {() => AuthContextValue} */
export const useAuth = () => {
  return useContext(AuthContext);
};
