import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAuth } from "./login/AuthContext";

const Layout = lazy(() => import("./shared/Layout"));
const TicketListScreen = lazy(() => import("./tickets/TicketListScreen"));
const CreateTicketScreen = lazy(() => import("./tickets/CreateTicketScreen"));
const TicketDetailsScreen = lazy(() => import("./tickets/TicketDetailsScreen"));

const LoginLayout = lazy(() => import("./login/LoginLayout"));
const LoginScreen = lazy(() => import("./login/LoginScreen"));
const ForgotPasswordScreen = lazy(() => import("./login/ForgotPasswordScreen"));

const AuthenticatedRoutes = () => {
  return (
    <Suspense fallback={null}>
      <Layout>
        <Switch>
          <Route path="/tickets" exact component={TicketListScreen} />
          <Route path="/tickets/new" exact component={CreateTicketScreen} />
          <Route
            path="/tickets/:ticketId"
            exact
            component={TicketDetailsScreen}
          />
          <Route path="*">
            <Redirect to="/tickets" />
          </Route>
        </Switch>
      </Layout>
    </Suspense>
  );
};

const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path={["/login", "/forgot-password"]}>
          <LoginLayout>
            <Switch>
              <Route path="/login" exact component={LoginScreen} />
              <Route
                path="/forgot-password"
                exact
                component={ForgotPasswordScreen}
              />
            </Switch>
          </LoginLayout>
        </Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Suspense>
  );
};

export const Routes = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />;
};
