import ky from "ky";
import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "../login/AuthContext";

export const useAPI = () => {
  const auth = useAuth();
  return useMemo(() => {
    return ky.extend({
      timeout: false,
      prefixUrl: process.env.REACT_APP_API_URL,
      hooks: {
        beforeRequest: [
          async (request) => {
            if (auth) {
              request.headers.set("Session-Token", auth.sessionToken);
            }
          },
        ],
        afterResponse: [
          async (_request, _options, response) => {
            if (response.status === 401 && auth) {
              const json = await response.json();
              // GLPI returns a 401 even if the request is authenticated
              // We check if the error is an array with "ERROR_RIGHT_MISSING" as its first element
              if (Array.isArray(json) && json[0] === "ERROR_RIGHT_MISSING") {
                return;
              }
              auth.logout();
            }
          },
        ],
      },
    });
  }, [auth]);
};

/** @typedef {{ [key in string]: any }} Resource */
/**
 * @typedef {{ range?: string, sort?: keyof T, order?: ("ASC" | "DESC"), searchText?: string }} GetAllSearchParams
 * @template {Resource} T
 */
/** @type {<T extends Resource>(endpoint: string, searchParams?: GetAllSearchParams<T>) => import("react-query").UseQueryResult<T[]>} */
export const useGetAllItems = (endpoint, searchParams = {}) => {
  const api = useAPI();
  return useQuery([endpoint, searchParams], async () =>
    api.get(endpoint, { searchParams }).json()
  );
};

/**
 * @typedef {{ with_documents?: boolean }} GetSearchParams
 */
/** @type {<T extends Resource>(endpoint: string, itemId: number, searchParams?: GetSearchParams) => import("react-query").UseQueryResult<T>} */
export const useGetItem = (endpoint, itemId, searchParams = {}) => {
  const api = useAPI();
  return useQuery(
    [`${endpoint}/${itemId}`, searchParams],
    async () => api.get(`${endpoint}/${itemId}`, { searchParams }).json(),
    {
      enabled: !!itemId,
    }
  );
};

/** @type {<T extends Resource>(endpoint: string, itemId: number, subitemsEndpoint: string, searchParams?: GetAllSearchParams<T>) => import("react-query").UseQueryResult<T[]>} */
export const useGetSubitems = (
  endpoint,
  itemId,
  subitemsEndpoint,
  searchParams = {}
) => {
  const api = useAPI();
  return useQuery(
    [`${endpoint}/${itemId}/${subitemsEndpoint}`, searchParams],
    async () =>
      api
        .get(`${endpoint}/${itemId}/${subitemsEndpoint}`, { searchParams })
        .json(),
    {
      enabled: !!itemId,
    }
  );
};

/** @type {<T>(endpoint: string) => import("react-query").UseMutationResult<{ id: number, message: string }, unknown, T>} */
export const useCreateItem = (endpoint) => {
  const queryClient = useQueryClient();
  const api = useAPI();
  return useMutation(
    async (item) => {
      return api
        .post(endpoint, {
          json: { input: item },
        })
        .json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(endpoint);
      },
    }
  );
};
